import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../../../shared/types/models/user.model";
import * as jwt_decode from 'jwt-decode';
import * as url from "url";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;

  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    // HTTP Response { user, token }
    return this.http.post<any>('api/login', { username, password })
      .pipe(map(response => {
        if (response && response.token) {
          localStorage.setItem('Calcufr8Token', JSON.stringify(response));
          localStorage.setItem('Calcufr8User', JSON.stringify(response.user));
        }

        return response;
      }));
  }

  logout(): void {
    localStorage.removeItem('Calcufr8Token');
    localStorage.removeItem('Calcufr8User');

    //console.log('logout ended');
    console.log(this.getUser());
  }

  getRedirect(): url {
    return this.redirectUrl || '/user/agent/jobs';
  }

  // Login State
  loginExpired(): boolean {
    const token = this.getToken();
    return !token || this.isTokenExpired(token.token);
  }
  isLoggedIn(): boolean {
    return !this.loginExpired();
  }

  isAgent(): boolean {
    console.log("Agent Checked");
    console.log(this.isAdmin());
    return this.isAdmin();
    //const user = this.getUser();
    //return user.hasPortal('agent');
  }

  isAuthenticated(): boolean {
    let authenticated = !!localStorage.getItem('Calcufr8Token');
    return authenticated && !this.isTokenExpired();
  }

  getToken(): any {
    return JSON.parse(localStorage.getItem('Calcufr8Token'));
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('Calcufr8User'));
  }

  setUser(user: User) {
    localStorage.setItem('Calcufr8User', JSON.stringify(user));
  }

  isAdmin(): boolean {
    const user = this.getUser();
    return !!user && (user.isAdmin || user.isCalcufr8Admin);
  }

  isCalcufr8Admin(): boolean {
    const user = this.getUser();
    return !!user && user.isCalcufr8Admin;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken().token;
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

}
