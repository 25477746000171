import {Component, Input, OnInit} from '@angular/core';
import {SelectListComponent} from "../select-list.component";

@Component({
  selector: 'app-select-list-item',
  templateUrl: './select-list-item.component.html',
  styleUrls: ['./select-list-item.component.scss']
})
export class SelectListItemComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  value: any;

  @Input()
  checked = false;

  @Input()
  disabled = false;

  show = true;

  constructor(public selectList: SelectListComponent) {
    selectList.addItem(this);
  }

  ngOnInit() {
  }

}
