import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input()
  identifier: string;

  @Input()
  title: string;

  @Input()
  destructive: boolean = false;

  @Output()
  confirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor(public ngxSmartModalService: NgxSmartModalService) {
  }

  ngOnInit() {
  }

  confirm(event): void {
    event.stopPropagation();
    this.ngxSmartModalService.getModal(this.identifier).close();
    this.confirmed.emit();
  }

  close(event): void {
    event.stopPropagation();
    this.ngxSmartModalService.getModal(this.identifier).close();
  }

}
