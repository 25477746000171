import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import {CheckListComponent} from "../check-list.component";

@Component({
  selector: 'app-check-list-item',
  templateUrl: './check-list-item.component.html',
  styleUrls: ['./check-list-item.component.scss']
})
export class CheckListItemComponent implements OnInit {

  faSquare = faSquare;
  faCheckSquare = faCheckSquare;

  @Input()
  title: string;

  @Input()
  value: any;

  @Input()
  subtitle: string;

  @Input()
  checked = false;

  @Input()
  disabled = false;

  @Input()
  show = true;

  constructor(public checkList: CheckListComponent) {
    checkList.addItem(this);
  }

  ngOnInit() {
  }

  toggleChecked(event: Event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.checked = !this.checked;
      this.checkList.toggleItem(this);
    }
  }
}
