import { Component, OnInit } from '@angular/core';
import { AuthService } from "./core/services/auth/auth.service";
import { User } from "./shared/types/models/user.model";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'Calcufr8';
  user: User;
  showOutline: boolean;

  constructor(public authService: AuthService, public router: Router) { }

  ngOnInit() {
    console.log('app init');
    this.user = this.authService.getUser();
  }

  activated(event): void {
    console.log("activated");
    console.log(this.router.url);
    //console.log(`pre showOutline: ${this.showOutline}`);
    //console.log(this.user);
    this.user = this.authService.getUser();
    this.showOutline = this.user !== null;
    console.log(`post showOutline: ${this.showOutline}`)
    console.log(this.user);
  }

  deactivated(event): void {
    console.log("deactivated");
    //console.log(`showOutline: ${this.showOutline}`)
    this.user = this.authService.getUser();
    this.showOutline = this.user !== null;
  }
}
