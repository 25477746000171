import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  UrlSegment, Route
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AlreadyLoggedInGuard implements CanActivate {

  constructor(private authService: AuthService, public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    // Check Login or Redirect to Login Page
    return !this.authService.isLoggedIn() || this.redirectToHome();
  }

  private redirectToHome() {
    return this.router.parseUrl('/user/agent/jobs');
    //return this.authService.getHomePage();
  }
}
