import {BaseModel} from "./base.model";
import {DataEntity} from "./data-entity.model";
import {Deserializable} from "../interfaces/deserializable.interface";

export class User extends BaseModel implements Deserializable {
  accountId: string;
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  isCalcufr8Admin: boolean;
  dataEntityId: string;
  dataEntity: DataEntity;
  portals: string[];

  constructor() {
    super();
    this.firstName = '';
    this.lastName = '';
    this.username = '';
    this.email = '';
    this.password = '';
    this.isAdmin = false;
    this.isCalcufr8Admin = false;
  }

  hasPortal(portalName: string): boolean {
    return this.portals.includes(portalName);
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.dataEntity) {
      this.dataEntity = new DataEntity().deserialize(input.dataEntity);
    }

    return this;
  }
}
