import { Directive } from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {EmailValidator, UsernameValidator} from "./email.validator";

@Directive({
  selector: '[emailAvailable][ngModel]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})
export class EmailValidatorDirective implements Validator {
  constructor(private emailValidator: EmailValidator) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.emailValidator.checkEmail(control);
  }
}

@Directive({
  selector: '[usernameAvailable][ngModel]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UsernameValidatorDirective, multi: true}]
})
export class UsernameValidatorDirective implements Validator {
  constructor(private usernameValidator: UsernameValidator) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.usernameValidator.checkUsername(control);
  }
}
