import { Component, OnInit } from '@angular/core';
import {ModalComponent} from "../../../shared/components/modal/modal.component";
import {NgxSmartModalService} from "ngx-smart-modal";
import {UserService} from "../../services/user/user.service";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends ModalComponent implements OnInit {

  userId: string;
  password: string;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public userService: UserService,
              public toastrService: ToastrService,
              public authService: AuthService, public router: Router) {
    super(ngxSmartModalService, 'changePasswordModal', 'Update Password', 'update')
  }

  ngOnInit() {
    this.bindData();
  }

  bindData() {
    const modalData = this.ngxSmartModalService.getModal('changePasswordModal').getData();

    if (modalData.userId) {
      this.userId = modalData.userId;
    }
  }

  update(): void {
    this.userService.updatePassword$(this.userId, this.password)
      .subscribe(user => {
        this.authService.logout();
        this.router.navigate(['/login']);
        this.toastrService.success('Password has been updated successfully.', 'Success!');
      });
  }

}
