import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faArrowRight, faArrowDown, faEdit, faTrash, faClone, faUser, faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  faArrowRight = faArrowRight;
  faArrowDown = faArrowDown;
  faEdit = faEdit;
  faTrash = faTrash;
  faClone = faClone;
  faUser = faUser;
  faFilePdf = faFilePdf;

  expandColumns: any[];
  multi: any[] = [];
  options: any;

  @Input()
  model: any;

  @Input()
  rows: any[];

  @Input()
  columns: any[];

  @Input()
  showEdit = true;

  @Input()
  showDelete = true;

  @Input()
  showDuplicate = true;

  @Input()
  showCreateUser = false;

  @Input()
  showSummary: boolean = false;

  @Input()
  showReports: boolean = false;

  @Output()
  onSelect: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onEdit: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onDelete: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onDuplicate: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onCreateUser: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onOpenReports: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('myTable', { static: false }) table: any;

  expanded: any = {};

  constructor() { }

  ngOnInit() {
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  rowSelected(row): void {
    this.onSelect.emit(row.id);
  }

  editRow(row): void {
    if (row.id) {
      this.onEdit.emit(row.id);
    } else {
      this.onEdit.emit(row.index);
    }
  }

  deleteRow(row): void {
    if (row.id) {
      this.onDelete.emit(row.id);
    } else {
      this.onDelete.emit(row.index);
    }
  }

  duplicateRow(row): void {
    if (row.id) {
      this.onDuplicate.emit(row.id);
    } else {
      this.onDuplicate.emit(row.index);
    }
  }

  createUser(row): void {
    if (row.id) {
      this.onCreateUser.emit(row.id);
    }
  }

  openReports(row): void {
    if (row.id) {
      this.onOpenReports.emit(row.id);
    }
  }
}
