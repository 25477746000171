import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  Route,
  UrlSegment, CanLoad, CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth/auth.service";

/*
  Redirects users without a valid user and token to the login page
 */

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private authService: AuthService,
              public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.isValid() || this.redirect(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isValid() || this.redirect(state.url);
  }

  loginRedirect(url: string) {
    this.authService.redirectUrl = url;
    return this.router.parseUrl('/login')
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isValid();
  }

  isValid(): boolean {
    return this.authService.isLoggedIn();
  }

  redirect(url: string) {
    this.authService.redirectUrl = url;
    return this.router.parseUrl('/login')
  }
}
