import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import {SelectListItemComponent} from "./select-list-item/select-list-item.component";
import {CheckListItemComponent} from "../check-list/check-list-item/check-list-item.component";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements AfterViewInit {

  items: SelectListItemComponent[] = [];

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  @Input()
  multiSelect: boolean = false;

  @Input()
  placeholder: string = 'Placeholder';

  @Output()
  itemsToggled: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled = false;

  dropdownOpen = false;

  // @ts-ignore
  @ViewChild('input')
  input: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(150),
        distinctUntilChanged(),
        tap((text) => {
          console.log(this.input.nativeElement.value);
          this.searchItems(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }

  searchItems(searchTerm: string) {
    const items = [];

    this.items.forEach(x => {
      if (!searchTerm || searchTerm == '') {
        x.show = true;
      } else {
        x.show = x.title.toLowerCase().includes(searchTerm.toLowerCase());
      }

      items.push(x);
    });

    this.items = items;
  }

  addItem(item: SelectListItemComponent): void {
    this.items.push(item);
  }

  toggleDropdown(event): void {
    event.stopPropagation();
    let elementId: string = (event.target as Element).id;
    if (!this.disabled && elementId != 'input') {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }

  selectChange(items: CheckListItemComponent[]): void {
    this.items.forEach(x => {
      x.checked = items.some(y => y.value === x.value);
    });

    let result = this.items.filter(x => x.checked);

    if (result.length == 0)
      this.itemsToggled.emit([{value: null }]);
    else
      this.itemsToggled.emit(result);

    if (!this.multiSelect) {
      this.dropdownOpen = false;
    }
  }

  getSelectedItems(): SelectListItemComponent[] {
    return this.items.filter(x => x.checked);
  }

  getTitle(): string {
    const selectedItems = this.getSelectedItems();
    if (selectedItems.length === 0) {
      return '';
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].title;
    }

    return selectedItems.length + ' items selected';
  }
}
