import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {HttpClient} from "@angular/common/http";
import {UserFilter} from "../../filters/user.filter";
import {User} from "../../../shared/types/models/user.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService<User> {
  public filter = new UserFilter();

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'user');
  }

  public getFilter(): UserFilter {
    return this.filter;
  }

  public emailAvailable(email: string): Observable<boolean> {
    const url = `${this.url}/email/${email}`;
    return this.httpClient.get<boolean>(url);
  }

  public usernameAvailable(username: string): Observable<boolean> {
    const url = `${this.url}/username/${username}`;
    return this.httpClient.get<boolean>(url);
  }

  public updatePassword$(id: string, password: string): Observable<User> {
    const url = `${this.url}/${id}/password`;
    return this.httpClient.put<User>(url, { password: password }, this.httpOptions);
  }

  public addDataEntity(id: string, dataEntityId: string): Observable<User> {
    const url = `${this.url}/${id}/dataentity/${dataEntityId}`;
    console.log(url);
    return this.httpClient.post<User>(url, {}, this.httpOptions);
  }

  public resetPassword(username: string): Observable<User> {
    const url = `${this.url}/reset/${username}`;
    return this.httpClient.post<User>(url, {}, this.httpOptions);
  }
}
