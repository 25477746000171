import {environment} from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";

export abstract class ApiService<T> {
  private baseUrl = environment.apiUrl;

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  public url: string;

  private itemAddedSource = new BehaviorSubject<string>(null);
  itemAdded$ = this.itemAddedSource.asObservable();

  itemAddedEvent(input) {
    this.itemAddedSource.next(input);
  }

  protected constructor(protected httpClient: HttpClient, protected apiUrl: string) {
    this.url = this.baseUrl + '/' + apiUrl;
  }

  get$(filter?:any, projection?: any): Observable<HttpResponse<any[]>> {
    let params: HttpParams = new HttpParams(); // All params are single level and labeled by dot syntax keys
    if (filter) {
      Object.entries(filter).forEach( ([key, value]) => {
        params = params.append(`filter.${key}`, (typeof value === 'string') ? value : JSON.stringify(value,));
      });
    }
    if (projection) {
      Object.entries(filter).forEach( ([key, value]) => {
        params = params.append(`projection.${key}`, (typeof value === 'string') ? value : JSON.stringify(value));
      });
    }
    console.log(params);
    return this.httpClient.get<any[]>(this.url, { params , observe: 'response' });
  }

  getById$(id: string): Observable<T> {
    return this.httpClient.get<T>(`${this.url}/${id}`);
  }

  create$(model: any): Observable<T> {
    return this.httpClient.post<T>(this.url, model, this.httpOptions)
  }

  update$(model: any): Observable<T> {
    return this.httpClient.put<T>(this.url, model, this.httpOptions);
  }

  delete$(id: string): Observable<T> {
    return this.httpClient.delete<T>(this.url + `/${id}`);
  }

  duplicate$(id: string): Observable<T> {
    const url = `${this.url}/${id}/duplicate`;
    return this.httpClient.post<T>(url, {}, this.httpOptions);
  }
}
