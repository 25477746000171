import { NgxSmartModalService } from 'ngx-smart-modal';
import { faTimes } from "@fortawesome/free-solid-svg-icons";


export abstract class ModalComponent {

  faTimes = faTimes;

  callback = this[this.callbackMethodName];

  protected constructor(public ngxSmartModalService: NgxSmartModalService,
                        public identifier: string,
                        public title: string,
                        public callbackMethodName: string) {
  }

  close(): void {
    const modal = this.ngxSmartModalService.getModal(this.identifier);
    this.ngxSmartModalService.resetModalData(this.identifier);
    modal.close();
  }

  confirm(): void {
    this.callback();
    this.close();
  }

}
