import {Component, Input, OnInit} from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {StepperComponent} from "../stepper.component";

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  active: boolean;

  @Input()
  title: string;

  @Input()
  icon: IconDefinition;

  @Input()
  showClear: boolean;

  @Input()
  valid: boolean;

  constructor(stepper: StepperComponent) {
    stepper.addStep(this);
  }

  ngOnInit() {
  }

}
