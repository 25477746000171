import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StepComponent} from "./step/step.component";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  steps: StepComponent[] = [];

  @Output()
  onNext: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onAddJob: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClear: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  addStep(step: StepComponent) {
    if (this.steps.length === 0) {
      step.active = true;
    }
    this.steps.push(step);
  }

  selectStep(step: StepComponent) {
    this.steps.forEach(s => {
      s.active = false;
    });
    step.active = true;
  }

  showPrevious(): boolean {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    return index !== 0;
  }

  showNext(): boolean {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    return index + 1 !== this.steps.length;
  }

  showAddJob(): boolean {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    return index + 1 == this.steps.length;
  }

  previous(): void {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    this.steps[index].active = false;
    this.steps[index - 1].active = true;
  }

  next(): void {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    this.steps[index].active = false;
    this.steps[index + 1].active = true;

    this.onNext.emit(index);
  }

  addJob(): void {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    this.onAddJob.emit(index);
  }

  showClear(): boolean {
    let step = this.steps.find(x => {
      return x.active;
    });

    return step.showClear;
  }

  clear(): void {
    let index = this.steps.findIndex(x => {
      return x.active;
    });
    this.onClear.emit(index);
  }

  activeStepValid(): boolean {
    let index = this.steps.findIndex(x => {
      return x.active;
    });

    let activeStep = this.steps[index];
    return activeStep.valid;
  }

}
