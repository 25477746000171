import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from "../../services/auth/auth.service";
import {  faShip, faPoundSign, faGlobeEurope, faCoins, faTable, faProjectDiagram, faCarSide, faUser, faUsersCog, faCogs, faQuestionCircle, faCalculator, faTags, faBars, faTimes, faKey } from "@fortawesome/free-solid-svg-icons";
import { NavLinkGroups } from "../../types/nav-link-groups.type";
import { Router } from "@angular/router";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnChanges {
  faTimes = faTimes;

  faShip = faShip;
  faPoundSign = faPoundSign;
  faGlobeEurope = faGlobeEurope;
  faCoins = faCoins;
  faTable = faTable;
  faProjectDiagram = faProjectDiagram;
  faCarSide = faCarSide;
  faUser = faUser;
  faUsersCog = faUsersCog;
  faCogs = faCogs;
  faQuestionCircle = faQuestionCircle;
  faCalculator = faCalculator;
  faTags = faTags;
  faBars = faBars;
  faKey = faKey;

  constructor(public authService: AuthService,
              public router: Router) { }

  @Input()
  navLinkGroups: NavLinkGroups;

  @Output()
  clicked = new EventEmitter<any>();

  ngOnInit() {
    //console.log("Nav Init")
  }

  ngOnChanges(changes:SimpleChanges) {
    //console.log("Nav Change Detected");
  }

  click (event): void {
    this.clicked.emit(event);
  }
}
