import { Pipe, PipeTransform } from '@angular/core';
import { User } from "../../shared/types/models/user.model";

@Pipe({
  name: 'showSiteOutline'
})
export class ShowSiteOutlinePipe implements PipeTransform {

  transform(value: User, ...args: any[]): boolean {
    return !!value;
  }

}
