import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IconProp } from "@fortawesome/fontawesome-svg-core";


@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleButtonComponent implements OnInit {

  constructor() { }

  @Input()
  activatedIcon: IconProp;

  @Input()
  deactivatedIcon: IconProp;

  @Input()
  active: boolean = false;

  @Output()
  click: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

  clicked(event) {
    this.click.emit(event);
  }
}
