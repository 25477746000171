import {NgModule, Optional, SkipSelf} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {InterceptorService} from "./interceptors/interceptor.service";
import { SiteOutlineComponent } from './components/site-outline/site-outline.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import {SharedModule} from "../shared/shared.module";
import { NavLinkGroupComponent } from './components/nav-link-group/nav-link-group.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ShowSiteOutlinePipe } from './pipes/show-site-outline.pipe';

@NgModule({
  declarations: [

  SiteOutlineComponent,

  NavLinkComponent,

  NavLinkGroupComponent,

  ChangePasswordComponent,

  NavigationBarComponent,

  ToggleButtonComponent,

  ShowSiteOutlinePipe],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
    exports: [
        SiteOutlineComponent
    ],
  entryComponents: [
    ChangePasswordComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded.  You should import CoreModule in the AppModule only.')
    }
  }
}
