import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {ToastrModule} from "ngx-toastr";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import { BankComponent } from './components/bank/bank.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {DatatableComponent} from "./components/datatable/datatable.component";
import {SelectListComponent} from "./components/select-list/select-list.component";
import {SelectListItemComponent} from "./components/select-list/select-list-item/select-list-item.component";
import {CheckListComponent} from "./components/check-list/check-list.component";
import {CheckListItemComponent} from "./components/check-list/check-list-item/check-list-item.component";
import {TimePickerComponent} from "./components/time-picker/time-picker.component";
import {ClickOutsideModule} from "ng-click-outside";
import {ConfirmationModalComponent} from "./components/confirmation-modal/confirmation-modal.component";
import {Ng5SliderModule} from "ng5-slider";
import {ClipboardModule} from "ngx-clipboard";
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step/step.component';
import {EmailValidatorDirective, UsernameValidatorDirective} from './directives/email-validator.directive';
import {EmailValidator, UsernameValidator} from "./directives/email.validator";
import {DragulaModule} from "ng2-dragula";
import {NewUserModalComponent} from "./user-components/new-user-modal/new-user-modal.component";
import {SidebarModule} from "ng-sidebar";
import { DatePickerComponent } from './components/date-picker/date-picker.component';

@NgModule({
  declarations: [BankComponent, DatatableComponent, SelectListComponent,
    SelectListItemComponent, CheckListComponent, CheckListItemComponent, TimePickerComponent, ConfirmationModalComponent,
    StepperComponent, StepComponent, EmailValidatorDirective, UsernameValidatorDirective, NewUserModalComponent, DatePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    FontAwesomeModule,
    NgbModule,
    NgxSpinnerModule,
    NgxSmartModalModule.forRoot(),
    NgxDatatableModule,
    ClickOutsideModule,
    Ng5SliderModule,
    ClipboardModule,
    DragulaModule.forRoot(),
    SidebarModule
  ],
  exports: [
    FormsModule,
    RouterModule,
    ToastrModule,
    FontAwesomeModule,
    NgbModule,
    BankComponent,
    NgxSpinnerModule,
    NgxSmartModalModule,
    NgxDatatableModule,
    ClickOutsideModule,
    Ng5SliderModule,
    ClipboardModule,
    DragulaModule,
    SidebarModule,
    DatatableComponent,
    SelectListComponent,
    SelectListItemComponent,
    CheckListComponent,
    CheckListItemComponent,
    TimePickerComponent,
    ConfirmationModalComponent,
    StepperComponent,
    StepComponent,
    EmailValidatorDirective,
    UsernameValidatorDirective,
    DatePickerComponent
  ],
  providers: [
    EmailValidator,
    UsernameValidator,
    DatePipe
  ],
  entryComponents: [
    NewUserModalComponent
  ]
})
export class SharedModule { }
