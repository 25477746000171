import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import {
  faShip,
  faPoundSign,
  faGlobeEurope,
  faCoins,
  faTable,
  faProjectDiagram,
  faCarSide,
  faUser,
  faUsersCog,
  faCogs, faQuestionCircle, faCalculator, faTags, faBars, faTimes, faKey
} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {NgxSmartModalService} from "ngx-smart-modal";
import {ChangePasswordComponent} from "../change-password/change-password.component";
import {User} from "../../../shared/types/models/user.model";
import { NavLinkGroups } from "../../types/nav-link-groups.type";

@Component({
  selector: 'app-site-outline',
  templateUrl: './site-outline.component.html',
  styleUrls: ['./site-outline.component.scss']
})
export class SiteOutlineComponent implements OnInit, OnChanges {

  faShip = faShip;
  faPoundSign = faPoundSign;
  faGlobeEurope = faGlobeEurope;
  faCoins = faCoins;
  faTable = faTable;
  faProjectDiagram = faProjectDiagram;
  faCarSide = faCarSide;
  faUser = faUser;
  faUsersCog = faUsersCog;
  faCogs = faCogs;
  faQuestionCircle = faQuestionCircle;
  faCalculator = faCalculator;
  faTags = faTags;
  faBars = faBars;
  faTimes = faTimes;
  faKey = faKey;

  @Input()
  user: User;

  @Input()
  showSiteOutline: boolean = false;

  sidebarOpen: boolean = false;
  // Dynamic Navigation Groups
  navLinkGroups: NavLinkGroups = [];

  constructor(public authService: AuthService,
              public router: Router,
              public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    if (this.authService.isAgent()) { this.navLinkGroups.push(this.agentLinks); }
    this.navLinkGroups.push(this.clientLinks);
  }

  ngOnChanges(changes:SimpleChanges) {
    //console.log("Outline Change Detected");
    //console.log(this.showSiteOutline);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
    this.user = null;
  }

  navigateToCalculator(): void {
    this.router.navigate(['/calculator']);
  }

  changePassword(): void {
    const modal = this.ngxSmartModalService.create('changePasswordModal', ChangePasswordComponent);
    const user = this.authService.getUser();
    modal.setData({userId: user.id});
    modal.open();
  }

  toggleSidebar(event): void {
    event.stopPropagation();
    this.sidebarOpen = !this.sidebarOpen;
  }

  closeSidebar(event): void {
    event.stopPropagation();
    if (this.sidebarOpen == true) {
      this.sidebarOpen = false;
    }
  }

  // These should be pulled from the user object as a list of portals
  clientLinks = {
    expanded: false,
    title: 'My Jobs',
    navLinks: [
      {
        icon: faShip,
        title: 'Jobs',
        route: 'user/client/jobs'
      },
      {
        icon: faTags,
        title: 'Quotes',
        route: 'user/client/quotes'
      }
    ]
  };

  agentLinks = {
    expanded: true,
    title: 'Total Shipping',
    navLinks: [
      {
        icon: faShip,
        title: 'Jobs',
        route: 'user/agent/jobs'
      },
      {
        icon: faTags,
        title: 'Quotes',
        route: 'user/agent/quotes'
      }
    ]
  }; // Dynamically Build this from Agent/Agency Data
}
