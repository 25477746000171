import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {faTimes, faPlus} from "@fortawesome/free-solid-svg-icons";
import { NavLinks } from "../../types/nav-links.type";
import { Router } from "@angular/router";

@Component({
  selector: 'app-nav-link-group',
  templateUrl: './nav-link-group.component.html',
  styleUrls: ['./nav-link-group.component.scss']
})
export class NavLinkGroupComponent implements OnInit, OnChanges {
  faPlus = faPlus;
  faTimes = faTimes;

  @Input()
  expanded = false;

  @Input()
  title: string;

  @Input()
  navLinks: NavLinks;

  @Input()
  route: string;

  @Input()
  level: number = 0;

  constructor(public router: Router) { }

  ngOnInit() {

  }

  ngOnChanges(changes:SimpleChanges) {
    //console.log(changes['expanded'].currentValue);
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  navigateToPortal() {
    console.log(this.route);
    if (!this.route) return; // Please fix me
    this.router.navigate([this.route])
  }
}
