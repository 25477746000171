import {BaseModel} from "./base.model";
import {Address} from "../interfaces/address.interface";
import {Deserializable} from "../interfaces/deserializable.interface";

export class DataEntity extends BaseModel implements Deserializable {
  id: string;
  createdByUserId: string;
  type: any;
  name: string;
  surname: string;
  address: Address;
  vat: string;
  reference: string;
  openingHours: string;

  constructor() {
    super();
    this.address = {
      addressOne: '',
      addressTwo: '',
      town: '',
      county: '',
      postCode: '',
      country: null,
      telephone: '',
      emailAddress: ''
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class CreateDataEntity {
  createdByUserId: string;
  type: any;
  name: string;
  surname: string;
  address: Address;
  vat: string;
  reference: string;
  openingHours: string;

  constructor() {
    this.name = '';
    this.surname = '';
    this.address = {
      addressOne: '',
      addressTwo: '',
      town: '',
      county: '',
      postCode: '',
      country: null,
      telephone: '',
      emailAddress: '',
    }
  }
}
