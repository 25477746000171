import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { UserGuard } from "./core/guards/user.guard";
import { AlreadyLoggedInGuard } from "./core/guards/already-logged-in.guard";


/* Route Order
  Static Path
  Empty Path
  Wildcard Path
 */

const routes: Routes = [
  // Public System
  {
    path: 'calculator',
    loadChildren: () => import('./modules/calculator/calculator.module').then(m => m.CalculatorModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    canActivate: [AlreadyLoggedInGuard] // Redirects logged in users to home
  },
  // Authenticated Portals
  {
    path: 'user',
    canActivate: [UserGuard],
    canActivateChild: [UserGuard],
    loadChildren: () => {
      console.log("Loading user-portal Module")
      return import('./portals/user-portal/user-portal.module').then(module => module.UserPortalModule)
    }
  },
  // Home
  {
    path: '',
    redirectTo: 'calculator',
    pathMatch: 'full'
  },
  // Wildcard Route (404 Page not Found)
  {
    path: '**',
    redirectTo: 'calculator'
  }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
