import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckListItemComponent} from "./check-list-item/check-list-item.component";

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {

  items: CheckListItemComponent[] = [];

  @Input()
  multiSelect: boolean = false;

  @Input()
  showCheckboxes: boolean = true;

  @Output()
  itemsToggled: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  itemToggled: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  addItem(item: CheckListItemComponent): void {
    this.items.push(item);
  }

  toggleItem(item: CheckListItemComponent): void {
    const checked = item.checked;
    if (!this.multiSelect) {
        this.items.forEach(x => x.checked = false);
        item.checked = checked;
    }
    const checkedItems = this.items.filter(x => x.checked);
    this.itemToggled.emit(item);
    this.itemsToggled.emit(checkedItems);
  }
}
