import { Component, OnInit } from '@angular/core';

import {NgxSmartModalService} from "ngx-smart-modal";

import {ToastrService} from "ngx-toastr";
import {ModalComponent} from "../../components/modal/modal.component";
import {User} from "../../types/models/user.model";
import {UserService} from "../../../core/services/user/user.service";

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss']
})
export class NewUserModalComponent extends ModalComponent implements OnInit {

  user: User = new User();

  editMode = false;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public userService: UserService, public toastrService: ToastrService) {
    super(ngxSmartModalService, 'newUserModal', 'New User', 'create')
  }

  ngOnInit() {
    this.bindData();
  }

  bindData() {
    const modalData = this.ngxSmartModalService.getModal('newUserModal').getData();

    if (modalData && modalData.userId) {
      this.userService.getById$(modalData.userId)
        .subscribe(user => {
          this.user = user;
          this.editMode = true;

          if (modalData.accountId) {
            this.user.accountId = modalData.accountId;
          }
        });
    }

    if (modalData && modalData.dataEntityId) {
      this.user.dataEntityId = modalData.dataEntityId;
    }
  }

  create(): void {
    if (this.editMode) {
      this.userService.update$(this.user)
        .subscribe(user => {
          this.userService.itemAddedEvent(user);
          this.toastrService.success('Item has been updated successfully.', 'Item Updated');
        });
    } else {
      console.log(this.user);
      this.userService.create$(this.user)
        .subscribe(user => {
          this.userService.itemAddedEvent(user);
          this.toastrService.success('Item has been created successfully.', 'Item Created');
        });
    }
  }

}
