import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {AuthService} from "../services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.authService.getToken();

    if (token && token.token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.token}`
        }
      });
    }

    return next.handle(req);
  }
}
