import {Component, Input, OnInit} from '@angular/core';
import { Route } from "@angular/router";

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnInit {

  @Input()
  icon: any;

  @Input()
  title: string;

  @Input()
  route: string;

  constructor() { }

  ngOnInit() {
  }

}
